$('.search-input').on('input', function () {
    if ($('.search-input').val() != '') {
        $('.input-clear-icon').css('display', 'block');
    } else {
        $('.input-clear-icon').css('display', 'none');
    }
})

$('.input-clear-icon').on('click', function (e) {
    $('.search-input').val('')
    $('.input-clear-icon').css('display', 'none');
})
